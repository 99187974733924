import {browserTracingIntegration, init, replayIntegration} from "@sentry/browser";

init({
    dsn                     : "https://4bc835e56fe14b478a83cd583cb2b5c0@o4505397567946752.ingest.us.sentry.io/4507567115730944",
    release                 : "reislog-cms-fe",
    integrations            : [browserTracingIntegration(), replayIntegration()],
    tracesSampleRate        : 1.0,
    tracePropagationTargets : [/^https:\/\/reislog\.com/],
    replaysSessionSampleRate: 0.33,
    replaysOnErrorSampleRate: 1.0,
});

